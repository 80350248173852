import { Routes, Route, useLocation } from "react-router-dom";
import Demo from "./pages/Demo";

import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";

import PrivateRoutesAdmin from "./utils/PrivateRoutesAdmin";
import PublicRouteAdmin from "./utils/PublicRouteAdmin";

import ExploreBooks from "./pages/ExploreBooks";
import Homepage from "./pages/Homepage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Whitepages from "./pages/Whitepages";
import ReadingSpace from "./pages/ReadingSpace";
import Welcome from "./pages/Welcome";
import SignUp from "./pages/SignUp";
import GetAccess from "./pages/GetAccess";
import BookPDF from "./pages/adminPanel/pages/BookPDF";
import CompleteBookPDF from "./pages/CompleteBookPDF";
import GetAccessDetails from "./pages/GetAccessDetails";
import PaymentSuccess from "./pages/PaymentSuccess";
import AdminPage from "./pages/adminPanel/pages/AdminPage";
import Category from "./pages/adminPanel/pages/Category";
import Customer from "./pages/adminPanel/pages/Customer";
import Password from "./pages/adminPanel/pages/Password";
import AdminLogin from "./pages/adminPanel/pages/AdminLogin";
import UserProfile from "./pages/User/UserProfile";
import EditProfile from "./pages/User/EditProfile";
import UserPassword from "./pages/User/UserPassword";
import { AnimatePresence } from "framer-motion";
import CompleteBookAdmin from "./pages/adminPanel/pages/CompleteBookAdmin";
import HomepageFilesUpload from "./pages/adminPanel/pages/HomepageFilesUpload";
import ResetPassword from "./pages/ResetPassword";
import Payment from "./pages/Payment";
import PdfViewerPage from "./pages/PdfViewerPage";

function App() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        <Route path="/admin" element={<PublicRouteAdmin />}>
          <Route path="/admin/login" element={<AdminLogin />} />
        </Route>
        {/* PRIVATE ADMIN ROUTES */}
        <Route path="/admin" element={<PrivateRoutesAdmin />}>
          <Route path="/admin/homepage" element={<HomepageFilesUpload />} />
          <Route path="/admin/dashboard" element={<AdminPage />} />
          <Route path="/admin/category" element={<Category />} />
          <Route
            path="/admin/viewPdf/:bookId/:pdfFilename"
            element={<BookPDF />}
          />
          <Route
            path="/admin/readPdf/:bookId/:pdfFilename"
            element={<CompleteBookAdmin />}
          />
          <Route path="/admin/customer" element={<Customer />} />
          <Route path="/admin/password" element={<Password />} />
        </Route>

        {/* Public user Routes */}
        <Route path="/" exact element={<Homepage />} />
        {/* <Route path="/demo" element={<Demo />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
        <Route path="/view-pdf/:title" element={<PdfViewerPage />} />

        {/* <Route path="/kashi" exact element={<Whitepages />} /> */}
        <Route path="/" element={<PublicRoute />}>
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route
            path="/reset-password/:userId/:userToken"
            element={<ResetPassword />}
          />
          <Route path="/request-otp" element={<Welcome />} />
          <Route path="/verify-otp/:userId" element={<Welcome />} />
        </Route>

        <Route path="/explorebooks" exact element={<ExploreBooks />} />
        <Route path="/getaccess/:bookId" element={<GetAccess />} />

        {/* PRIVATE USER ROUTES */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/payment" element={<Payment />} />
          <Route path="/myprofile" element={<UserProfile />} />
          <Route path="/myprofile/edit" element={<EditProfile />} />
          <Route path="/myprofile/password" element={<UserPassword />} />
          <Route path="/readingspace" element={<ReadingSpace />} />
          <Route
            path="/getaccess/:bookId/details"
            element={<GetAccessDetails />}
          />
          <Route
            path="/getaccess/:bookId/payment"
            element={<PaymentSuccess />}
          />
          {/* <Route path="/viewPdf/:bookId/:pdfFilename" element={<BookPDF />} /> */}
          <Route
            path="/readPdf/:bookId/:pdfFilename"
            element={<CompleteBookPDF />}
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default App;
