import React from "react";
import { BASE_URL } from "../../../apiConfig";

import classes from "./BookPDF.module.css";
import { useParams } from "react-router-dom";

function BookPDF() {
  const params = useParams();

  return (
    <div className={classes.mainContainer}>
      <iframe
        src={`${BASE_URL}/user/get/files/${params.pdfFilename}#view=fitH`}
        title="testPDF"
        height="100%"
        width="100%"
      />
    </div>
  );
}

export default BookPDF;
