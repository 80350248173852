import React from "react";
import PdfViewer from "./PdfViewer.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { BASE_URL } from "../apiConfig";
import axios from "axios";
import classes from "./PdfViewerPage.module.css";
import CircularProgress from "@mui/material/CircularProgress";

function PdfViewerPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPdfFile = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/user/get/files/${params.title}`,

          {
            responseType: "blob", // Important: responseType should be set to "blob"
            withCredentials: true,
          }
        );
        const pdf = response.data;
        const url = URL.createObjectURL(pdf);
        setPdfUrl(url); //we pass url object to react-pdf viewer, coz it expects string or url object as fileUrl

        // setTimeout(() => {
        setIsLoading(false);
        // }, 1000);
      } catch (error) {
        console.log("Error fetching PDF file:", error);
      }
    };

    fetchPdfFile();
  }, [params.title]);

  function pdfViewerCloseHandler() {
    navigate(-1);
  }

  if (isLoading) {
    return (
      <div className="progressContainer">
        <CircularProgress />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className={classes.pdfViewerContainer}>
      {!isLoading && (
        <PdfViewer
          pdf={pdfUrl}
          onClose={pdfViewerCloseHandler}
          loading={isLoading}
        />
      )}
    </div>
  );
}

export default PdfViewerPage;
