// when user clicks on get access

import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import classes from "./GetAccessDetails.module.css";

import { useNavigate, useParams } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import { CircularProgress } from "@mui/material";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";

function GetAccessDetails() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [book, setBook] = useState({});
  const params = useParams();

  const [userDetails, setUserDetails] = useState({
    purchase: "",
    mobile: "",
    email: "",
    fName: "",
    lName: "",
    city: "",
    country: "",
    purchasedBooks: [],
  });

  const [isAgree, setIsAgree] = useState(true);
  const {
    purchase,
    mobile,
    email,
    fName,
    lName,
    city,
    country,
    purchasedBooks,
  } = userDetails;

  function changeHandler(e) {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
    console.log("Choice" + purchase);
  }

  function handleTermsCheck(e) {
    setIsAgree((current) => !current);
    if (isAgree) {
      console.log("agree");
    } else if (!isAgree) {
      console.log("don't agree");
    }
    console.log(isAgree);
  }

  // GETTING SPECIFIC BOOK DETAILS FOR WHICH ACCESS REQUESTED BY USER
  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${params.bookId}`)
      .then((res) => {
        setBook(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [params.bookId]);

  // GETTING USER DETAILS FOR PURCHASE FORM
  useEffect(() => {
    axios
      .get(`${BASE_URL}/user/details`, {
        withCredentials: true,
      }) //set unique route with user id in url to get specific books for each user in reading space
      .then((res) => {
        // setAvlBooks(res.data);
        console.log(res.data);
        const userData = res.data;
        setUserDetails({
          mobile: userData.mobile,
          email: userData.email,
          fName: userData.fName,
          lName: userData.lName,
          city: userData.city,
          country: userData.country,
          purchasedBooks: userData.purchasedBooks,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function formSubmitHandler(e) {
    e.preventDefault();
    // console.log("Sending purchase request...");

    if (!purchase) {
      toast.error("Please select buying option!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!isAgree) {
      if (purchase === "single-book") {
        try {
          const response = await axios.post(
            // `${BASE_URL}/user/purchase/${book._id}`,
            `${BASE_URL}/instamojo/pay`,
            {
              bookPurchasedId: book._id,
              bookDetail: `White Pages (Book: ${book.bookNumber})`,
              price: book.price,
              purchaseOption: purchase, //"single-book"
            },
            {
              withCredentials: true,
            }
          );
          console.log(book._id, book.title, book.price, purchase);
          const paymentUrl = response.data;
          console.log(response.data);
          window.location.href = paymentUrl;
        } catch (err) {
          console.error("Error purchasing book!", err);
          toast.error("Error purchasing book!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      } else if (purchase === "series") {
        try {
          const response = await axios.post(
            // `${BASE_URL}/user/purchase/series`,
            `${BASE_URL}/instamojo/pay`,
            {
              bookPurchasedId: book._id,
              bookDetail: "White Pages Physics Series",
              price: "500",
              purchaseOption: purchase, //"series"
            },
            {
              withCredentials: true,
            }
          );
          console.log(purchase);
          const paymentUrl = response.data;
          console.log(response.data);
          window.location.href = paymentUrl;
        } catch (err) {
          console.error("Error purchasing book series!", err);
          toast.error("Error purchasing series!", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }
    } else {
      toast.info("Please agree to T&C!", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log("Agree to terms");

      return;
    }
  }

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <Header />
        {isLoading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.leftColumn}>
              <img
                src={`${BASE_URL}/user/get/files/${book.coverImg}`}
                alt={`book-cover-${book.title}`}
                className="img-fluid"
              />
              <div className={classes.available}>
                <p className={classes.status}>Available</p>
                <p className={classes.price}>{book.price}/-</p>
              </div>
            </div>

            <div className={classes.rightColumn}>
              <div className={classes.formHeader}>
                <h3>Get Access to {book.title}</h3>
                <h3>Select purchasing option</h3>
              </div>

              {/* FORM */}
              <form onSubmit={formSubmitHandler}>
                <div className={classes.purchaseOptions}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="purchase"
                      id="purchaseBook"
                      value="single-book"
                      checked={purchase === "single-book"}
                      onChange={changeHandler}
                    />
                    <label className="form-check-label" htmlFor="purchaseBook">
                      Purchase {book.title} @ {book.price}/-
                    </label>
                  </div>
                  {/* if user has purchased even one book already, don't show purchase series option */}
                  {purchasedBooks.length === 0 && (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="purchase"
                        id="purchaseSeries"
                        value="series"
                        defaultChecked={purchase === "series"}
                        onChange={changeHandler}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="purchaseSeries"
                      >
                        Purchase complete white pages Physics series @ 500/-
                      </label>
                    </div>
                  )}
                </div>

                <div className={classes.contact}>
                  <h3>Billing Details</h3>
                  <div className={classes.contactDetails}>
                    <input
                      type="number"
                      name="mobile"
                      placeholder="Mobile"
                      onChange={changeHandler}
                      value={mobile}
                      disabled
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={changeHandler}
                      value={email}
                      disabled
                    />
                  </div>
                </div>

                <div className={classes.userDetails}>
                  <input
                    type="text"
                    name="fName"
                    placeholder="First Name"
                    onChange={changeHandler}
                    value={fName}
                    disabled
                  />
                  <input
                    type="text"
                    name="lName"
                    placeholder="Last Name"
                    onChange={changeHandler}
                    value={lName}
                    disabled
                  />
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    onChange={changeHandler}
                    value={city}
                    disabled
                  />
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    onChange={changeHandler}
                    value={country}
                    disabled
                  />
                </div>

                <div className={classes.termsConditions}>
                  <ol style={{ listStyle: "none" }}>
                    <p>T & C</p>
                    <li>1. The book/s is/are available for online reading.</li>

                    <li>2. Amount paid is non-refundable.</li>
                    <li>
                      3. Recreation of the book/s in any other form and/or
                      commercial distribution is invalid and illegal.
                    </li>
                    {/* <li>
                      Amount once paid is non-refundable under all
                      circumstances.
                    </li>
                    <li>
                      The kashi's take the full responsibilty of any issue in
                      purchase order or reading experience.
                    </li> */}
                  </ol>
                </div>

                <div className={`${"form-check"} ${classes.termsCheck}`}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={isAgree}
                    onChange={handleTermsCheck}
                    id="termsCheck"
                    name="termsCheck"
                  />
                  <label
                    className={`${"form-check-label"} ${classes.termsChecks}`}
                    htmlFor="termsCheck"
                  >
                    I have read the T&C and I agree.
                  </label>
                </div>
                <div className={classes.formAction}>
                  <button type="submit">Proceed to Payment</button>
                </div>
              </form>
            </div>
          </div>
        )}
        <ToastContainer />
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default GetAccessDetails;
