import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "./Welcome.module.css";
import PdfViewer from "./PdfViewer.tsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import brandLogo from "../assets/logos/brand-logo-light.jpg";
import bookLogo from "../assets/logos/book-logo-light.png";
import AnimatedPage from "../components/AnimatedPage";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";

function ResetPassword() {
  const params = useParams();
  const { userId, userToken } = params;
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState(true);

  const { newPassword, confirmPassword } = inputValue;

  useEffect(() => {
    const verifyUrl = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/user/forgot-password/${userId}/${userToken}`
        );
        console.log(response.data);
        if (response.status === 200) {
          setIsValid(true);
        } else {
          setIsValid(false);
          setMessage("Password reset link expired or invalid!");
        }
      } catch (error) {
        console.log(error);
        setIsValid(false);
        setMessage("Password reset link expired or invalid!");
      }
    };
    verifyUrl();
  }, [userId, userToken]);

  function handleOnChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  async function resetPasswordHandler(e) {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords don't match!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    try {
      const { data } = await axios.post(
        `${BASE_URL}/user/reset-password/${userId}/${userToken}`,
        {
          ...inputValue,
        }
        // { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/welcome");
        }, 1500);
      } else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
    setInputValue({
      ...inputValue,
      newPassword: "",
      confirmPassword: "",
    });
  }

  const handleOpenPdf = async (pdfUrl) => {
    console.log(pdfUrl);
    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Important: responseType should be set to "blob"
        // withCredentials: true,
      });
      const pdf = response.data;
      const url = URL.createObjectURL(pdf);
      setSelectedPdfUrl(url);
      //   setIsLoading(false);
      setOpenPdfViewer(true);
    } catch (error) {
      console.log("Error fetching PDF file:", error);
    }
  };

  function pdfViewerCloseHandler() {
    setOpenPdfViewer(false);
  }

  const pdfViewer = (
    <PdfViewer pdf={selectedPdfUrl} onClose={pdfViewerCloseHandler} />
  );

  if (openPdfViewer) {
    return pdfViewer;
  }

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <div className={classes.header}>
          <div className={classes.headerLogo}>
            <Link to="https://kashispace.live/">
              <img className={classes.brand} src={brandLogo} alt="brand-logo" />
            </Link>
          </div>
          <Link
            onClick={() => handleOpenPdf(`${BASE_URL}/user/get/files/whyPdf`)}
          >
            Introduction
          </Link>
        </div>

        <div className={classes.welcomeBox}>
          {/* LEFT CONTAINER */}
          <div className={classes.leftContainer}>
            {/* <button onClick={notify}>Notify!</button> */}
            <div className={classes.bookName}>
              <img src={bookLogo} alt="whitepages-logo" />
            </div>
            <div className={classes.footerLinks}>
              <Link
                onClick={() =>
                  handleOpenPdf(`${BASE_URL}/user/get/files/mainPreviewPdf`)
                }
              >
                Preview
              </Link>
              <Link
                onClick={() =>
                  handleOpenPdf(`${BASE_URL}/user/get/files/authorPdf`)
                }
              >
                Author
              </Link>
            </div>
          </div>
          <hr color="black" />
          {/* RIGHT CONTAINER */}
          <div className={classes.rightContainer}>
            {/* PASSWORD RESEST FORM */}
            <form onSubmit={resetPasswordHandler}>
              <h4>Forgot Password</h4>

              <input
                onChange={handleOnChange}
                value={newPassword}
                type="password"
                name="newPassword"
                placeholder="New Password"
              />
              <input
                onChange={handleOnChange}
                value={confirmPassword}
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
              />
              <div
                className={classes.formActions}
                style={{ marginTop: "50px" }}
              >
                <div className={classes.logIn}>
                  <button type="submit">Reset Password</button>
                </div>

                <div style={{ margin: "15px 0", fontSize: "0.9rem" }}>
                  <p
                    style={{ marginBottom: 0, color: isValid ? "grey" : "red" }}
                  >
                    {message}
                  </p>
                  {!isValid && <Link to="/welcome">Go back</Link>}
                </div>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default ResetPassword;
