// HOMEPAGE

import React from "react";
import { Link } from "react-router-dom";
import classes from "./Homepage.module.css";
import brandLogo from "../assets/logos/brand-logo-dark.jpg";
import bookLogo from "../assets/logos/book-logo-dark.png";
import GoToTop from "../components/GoToTop";

function Homepage() {
  return (
    <div className={classes.mainContainer}>
      <GoToTop />
      {/* HEADER */}
      <div className={classes.header}>
        <Link to="https://kashispace.live/">
          <img className={classes.brand} src={brandLogo} alt="brand-logo" />
        </Link>
        <Link
          to={`/view-pdf/whyPdf`}
          className={classes.headerText}
          // target="_blank"
        >
          Introduction
        </Link>
      </div>

      <div className={classes.innerContainer}>
        {/* BOOK NAME */}
        <div className={classes.bookName}>
          <img src={bookLogo} alt="whitepages-logo" />
        </div>

        {/* FOOTER LINKS */}
        <div className={classes.footerLinks}>
          <Link
            to={`/view-pdf/mainPreviewPdf`}
            // target="_blank"
          >
            Preview
          </Link>
          <Link to="/readingspace">Books</Link>
          <Link
            to={`/view-pdf/authorPdf`}
            // target="_blank"
          >
            Author
          </Link>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
}

export default Homepage;
