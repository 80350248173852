import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import classes from "./CompleteBookPDF.module.css";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PdfViewer from "./PdfViewer.tsx";
import GoToTop from "../components/GoToTop";
import CircularProgress from "@mui/material/CircularProgress";

const animations = {
  initial: { x: 100 },
  animate: { x: 0 },
  exit: { x: -100 },
};

function CompleteBookPDF() {
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState("");
  const [openNav, setOpenNav] = useState(false);
  const [book, setBook] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();

  // TO GET A PDF FILE AND DISPLAY IN IFRAME
  useEffect(() => {
    axios
      .get(`${BASE_URL}/books/${params.bookId}`)
      .then((res) => {
        console.log(res.data);
        setBook(res.data);
        // console.log(book);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.bookId]);

  useEffect(() => {
    const fetchPdfFile = async () => {
      try {
        const response = await axios.get(
          // `${BASE_URL}/books/files/${params.pdfFilename}`,
          `${BASE_URL}/user/completePdf/${params.pdfFilename}`,

          {
            responseType: "blob", // Important: responseType should be set to "blob"
            withCredentials: true,
          }
        );
        const pdf = response.data;
        const url = URL.createObjectURL(pdf);
        console.log("urlllllll", url.blob);
        setPdfUrl(url); //we pass url object to react-pdf viewer, coz it expects string or url object as fileUrl
        // setTimeout(() => {
        setIsLoading(false);
        // }, 5000);
      } catch (error) {
        console.log("Error fetching PDF file:", error);
      }
    };

    fetchPdfFile();
  }, [params.pdfFilename]);

  function openNavHandler() {
    setOpenNav(true);
  }

  function closeNavHandler() {
    setOpenNav(false);
    // setTimeout(() => {
    //   setOpenNav(false);
    // }, 1000);
  }

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function pdfViewerCloseHandler() {
    navigate("/readingspace");
  }

  async function postReviewHandler(e) {
    e.preventDefault();
    console.log(rating);
    console.log(comment);
    setIsModalOpen(false);
    try {
      const response = await axios.post(
        `${BASE_URL}/user/${params.bookId}/postReview`,
        {
          rating,
          comment,
        },
        { withCredentials: true }
      );

      console.log("Review saved successfully:", response.data.review);
      toast.success("Review posted!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setRating("");
      setComment("");
    } catch (error) {
      toast.error("Error posting review!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log("Error saving review:", error.response.data);
    }
  }

  const postReviewModal = (
    <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Fade in={isModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            outline: "none",
            width: "50%", //default width for all screens
            "@media (max-width: 768px)": {
              width: "85%" /* Width for screens up to 768px */,
            },
            "@media (max-width: 480px)": {
              width: "95%" /* Width for screens up to 480px */,
            },
            // height: "330px",
            padding: "10px 15px",
            bgcolor: "#FFF",
            borderRadius: "4px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <form onSubmit={postReviewHandler}>
            <h4 className={classes.reviewHeader}>Post a review</h4>
            <div className={classes.rating}>
              <p>Rate this book:</p>
              <Rating
                name="rating"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
              />
            </div>
            <TextField
              id="outlined-multiline-static"
              label="Write your review..."
              name="comment"
              sx={{ width: "100%", marginBottom: "20px" }}
              multiline
              rows={5}
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              // defaultValue="Default Value"
            />
            <div className={classes.postReview}>
              <button type="submit" className="btn btn-primary">
                Post Review
              </button>
            </div>
          </form>
        </Box>
      </Fade>
    </Modal>
  );

  const responsiveSideNavbar = (
    <motion.div
      // onMouseOut={closeNavHandler}
      className={classes.sideNav}
      id="sideNav"
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.6 }}
    >
      <button onClick={closeNavHandler} className={classes.indexBtn}>
        Index
      </button>

      {/* TO SET THE INDEX PROVIDED BY THE ADMIN THROUGH RICH TEXT EDITOR aka CKE EDITOR */}
      <div dangerouslySetInnerHTML={{ __html: book.index }} />
    </motion.div>
  );

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the context menu from appearing
  };

  if (isLoading) {
    return (
      <div className="progressContainer">
        <CircularProgress />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
      <GoToTop />
      <div className={classes.gridContainer} onContextMenu={handleContextMenu}>
        <div onClick={closeNavHandler} className={classes.gridItem}>
          {/* PDF VIEWER COMPONENT */}
          {!isLoading && (
            <PdfViewer
              pdf={pdfUrl}
              onClose={pdfViewerCloseHandler}
              loading={isLoading}
            />
          )}
        </div>
        {!openNav ? (
          <div className={classes.gridItem2}>
            <button onClick={openNavHandler} className={classes.expandBtn}>
              {/* Index */}
              <ArrowBackIosIcon />
            </button>
            <Fab
              sx={{ position: "absolute", bottom: "15px", right: "25px" }}
              onClick={handleOpenModal}
              color="primary"
              variant="extended"
            >
              <AddCommentIcon sx={{ mr: 1 }} />
              Review
            </Fab>
          </div>
        ) : (
          responsiveSideNavbar
        )}

        {postReviewModal}
        <ToastContainer />
      </div>
    </>
  );
}

export default CompleteBookPDF;
