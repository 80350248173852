// open this when user click on a book from slider

import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../apiConfig";
import Header from "../components/Header";
import classes from "./ExploreBooks.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";
import AnimatedPage from "../components/AnimatedPage";
import { motion } from "framer-motion";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { Modal, Box } from "@mui/material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import Footer from "../components/Footer";
import PdfViewer from "./PdfViewer.tsx";
import GoToTop from "../components/GoToTop";

const variants = {
  visible: (i) => ({
    opacity: 1,
    transition: {
      delay: i * 0.6,
    },
  }),
  hidden: { opacity: 0 },
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

function ExploreBooks() {
  const [books, setBooks] = useState([]);
  const [user, setUser] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [isReviewsModalOpen, setReviewsModalOpen] = useState(false);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);

  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");

  useEffect(() => {
    axios
      .get(`${BASE_URL}/books`)
      .then((res) => {
        //sort books based on bookNumber field
        const sortedBooks = res.data.sort(
          (a, b) => a.bookNumber - b.bookNumber
        );
        setBooks(sortedBooks);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

    axios
      .get(`${BASE_URL}/user/details`, { withCredentials: true })
      .then((res) => {
        setUser(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleOpenPdf = async (pdfUrl) => {
    console.log(pdfUrl);
    try {
      const response = await axios.get(pdfUrl, {
        responseType: "blob", // Important: responseType should be set to "blob"
        // withCredentials: true,
      });
      const pdf = response.data;
      const url = URL.createObjectURL(pdf);
      setSelectedPdfUrl(url);
      //   setIsLoading(false);
      setOpenPdfViewer(true);
    } catch (error) {
      console.log("Error fetching PDF file:", error);
    }
  };

  //   function handleOpenPdfModal(pdfUrl) {
  //     setSelectedPdfUrl(pdfUrl);
  //     setPdfModalOpen(true);
  //   }

  async function handleOpenReviewsModal(bookId) {
    setReviewsModalOpen(true);

    try {
      const response = await axios.get(`${BASE_URL}/books/${bookId}/reviews`);
      const fetchedReviews = response.data.reviews;
      console.log(fetchedReviews);
      setReviews(fetchedReviews);
      setReviewsLoading(false);
    } catch (error) {
      console.log("Error fetching reviews:", error);
      setReviewsLoading(false);
    }
  }

  // FUNCTION TO GET DATE (createdAt from review model) AND FORMAT IT FOR DISPLAYING
  const formatReviewDate = (createdAt) => {
    const now = moment(); // Current date and time
    const reviewDate = moment(createdAt);

    if (now.isSame(reviewDate, "day")) {
      // If it's the same day, show the time ago
      return reviewDate.fromNow();
    } else {
      // Otherwise, format as dd mm yyyy
      return reviewDate.format("DD MMM YYYY");
    }
  };

  function pdfViewerCloseHandler() {
    setOpenPdfViewer(false);
  }

  const pdfViewer = (
    <PdfViewer pdf={selectedPdfUrl} onClose={pdfViewerCloseHandler} />
  );

  const reviewsModal = (
    <Modal open={isReviewsModalOpen} onClose={() => setReviewsModalOpen(false)}>
      <Fade in={isReviewsModalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            "@media (max-width: 768px)": {
              width: "85%" /* Width for screens up to 768px */,
            },
            "@media (max-width: 480px)": {
              width: "95%" /* Width for screens up to 480px */,
            },
            maxHeight: "500px",
            padding: "10px", //new
            overflow: "auto", //new
            bgcolor: "#FFF",
            borderRadius: "4px",
            boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
          }}
        >
          <div className={classes.reviewsHeader}>
            <h4>Reader Reviews</h4>
          </div>

          {reviewsLoading ? (
            <div className={classes.loadingDiv}>
              <CircularProgress color="inherit" />
            </div>
          ) : reviews.length > 0 ? (
            reviews.map((review) => (
              <div key={review.id}>
                <Paper sx={{ marginBottom: "10px" }} elevation={3}>
                  <div className={classes.reviewCardContent}>
                    <div className={classes.rating}>
                      <div className={classes.nameWithDate}>
                        <p className={classes.name}>{review.user}</p>
                        <p className={classes.date}>
                          reviewed {formatReviewDate(review.createdAt)}
                        </p>
                      </div>
                      {/* <p className={classes.name}>{review.user}</p> */}
                      <Rating
                        name="rating"
                        size="small"
                        value={review.rating}
                        readOnly
                      />
                    </div>
                    {review.comment && (
                      <p className={classes.comment}>{review.comment}</p>
                    )}
                  </div>
                </Paper>
              </div>
            ))
          ) : (
            <div className={classes.noReviews}>
              <p>No reviews yet!</p>
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );

  if (openPdfViewer) {
    return pdfViewer;
  }

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <Header />
        {isLoading ? (
          <div className={classes.loadingDiv}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Carousel responsive={responsive}>
            {books.map((book, i) => (
              <motion.div
                key={book._id}
                className={classes.wrapper}
                custom={i}
                animate="visible"
                variants={variants}
                initial="hidden"
              >
                <div className={classes.leftColumn}>
                  <img
                    src={`${BASE_URL}/user/get/files/${book.coverImg}`}
                    alt={`book-cover-${book.title}`}
                    className="img-fluid"
                  />

                  <div className={classes.status}>
                    {book.status === "yes" ? (
                      <p className={classes.avl}>Available</p>
                    ) : (
                      <p className={classes.unavl}>{book.arrivalDate}</p>
                    )}
                    <p className={classes.price}>{book.price}/-</p>
                  </div>
                </div>
                <div className={classes.rightColumn}>
                  <div className={classes.bookActions}>
                    <Link to={`/view-pdf/${book.previewPdf}`}>Preview</Link>
                    <Link to={`/view-pdf/${book.noteToReader}`}>
                      Note to Reader
                    </Link>
                    <Link to={`/view-pdf/${book.contentTable}`}>
                      Table of Content
                    </Link>
                    <Link onClick={() => handleOpenReviewsModal(book._id)}>
                      Reviews
                    </Link>
                  </div>
                  <div className={classes.access}>
                    {book.status === "yes" &&
                      !user?.purchasedBooks?.includes(book._id) && (
                        <Link to={`/getaccess/${book._id}/details`}>
                          Get Access
                        </Link>
                      )}
                    {book.status === "yes" &&
                      user?.purchasedBooks?.includes(book._id) && (
                        <Link to={`/readPdf/${book._id}/${book.completePdf}`}>
                          Start Reading
                        </Link>
                      )}
                    {book.status === "no" && (
                      <Link to="#" className={classes.unavlLink}>
                        Get Access
                      </Link>
                    )}
                  </div>
                </div>
              </motion.div>
            ))}
          </Carousel>
        )}

        {/* {pdfModal} */}
        {reviewsModal}
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default ExploreBooks;
