import React, { useState, useEffect } from "react";
import { BASE_URL } from "../apiConfig";

import Header from "../components/Header";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

import classes from "./PaymentSuccess.module.css";

// import one from "../assets/books/1.png";
import success from "../assets/success.png";
import AnimatedPage from "../components/AnimatedPage";
import Footer from "../components/Footer";
import GoToTop from "../components/GoToTop";

function PaymentSuccess() {
  const [book, setBook] = useState({});
  const [isPayment, setIsPayment] = useState(false);
  const [payment, setPayment] = useState({
    id: "",
    amount: "",
    status: "",
    purpose: "",
  });
  const params = useParams();

  useEffect(() => {
    // TO GET A SPECIFIC BOOK DETAILS
    axios
      .get(`${BASE_URL}/books/${params.bookId}`)
      .then((res) => {
        setBook(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const urlSearchParams = new URLSearchParams(window.location.search);
    const paymentRequestId = urlSearchParams.get("request_id");

    // TO GET PAYMENT DETAILS BY PAYMENT REQUEST ID
    axios
      .get(`${BASE_URL}/instamojo/status/${paymentRequestId}`)
      .then((res) => {
        console.log(res.data);
        const payments = res.data.payment_request.payments;
        const paymentDetails = res.data?.payment_request?.payments[0];
        // console.log(
        //   paymentDetails.payment_id,
        //   paymentDetails.amount,
        //   paymentDetails.status
        // );
        if (payments.length > 0) {
          setIsPayment(true);
          console.log("Payment exists");

          setPayment({
            id: paymentDetails?.payment_id,
            amount: paymentDetails?.amount,
            status: paymentDetails?.status,
            purpose: res.data.payment_request?.purpose,
          });
        }
        // setBook(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.bookId]);

  return (
    <AnimatedPage>
      <GoToTop />
      <div className={classes.mainContainer}>
        <Header />
        <div className={classes.wrapper}>
          <div className={classes.leftColumn}>
            <img
              src={`${BASE_URL}/user/get/files/${book.coverImg}`}
              alt={`book-cover-${book.title}`}
              className="img-fluid"
            />
            <div className={classes.available}>
              <p className={classes.status}>Available</p>
              <p className={classes.price}>{book.price}/-</p>
            </div>
          </div>
          {payment.status === "Credit" && (
            <div className={classes.rightColumn}>
              <div className={classes.success}>
                <img src={success} alt="green-tick" />
                <h3>Payment Successful</h3>
                <p>Payment Id - {payment.id}</p>
                {/* <p>Payment status - {payment.status}</p> */}
                <p>Amount - ₹{payment.amount}</p>
                <p>The receipt has been sent to your registered email.</p>
              </div>
              <div className={classes.confirmation}>
                <p>{payment.purpose} added to your reading space.</p>
              </div>
              <div className={classes.actions}>
                <Link to={`/readPdf/${book._id}/${book.completePdf}`}>
                  Start Reading
                </Link>
                <Link to="/explorebooks">Explore Books</Link>
              </div>
            </div>
          )}
          {(payment.status === "Failed" || isPayment === false) && ( //isPayment will be false for pending payments, when payment id could not be generated
            <div className={classes.rightColumn}>
              <div className={classes.success}>
                <h3 style={{ color: "red" }}>Payment Unsuccessful</h3>
                {isPayment === false && (
                  <p>
                    Payment Request Id -{" "}
                    {new URLSearchParams(window.location.search).get(
                      "request_id"
                    )}
                  </p>
                )}
                {isPayment === true && (
                  <>
                    <p>Payment Id - {payment.id}</p>
                    <p>Payment status - {payment.status}</p>
                    <p>Amount - ₹{payment.amount}</p>
                  </>
                )}
              </div>
              <div className={classes.confirmation}>
                {isPayment ? (
                  <p>{payment.purpose} could not be purchased.</p>
                ) : (
                  <p>Please try again later!</p>
                )}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </AnimatedPage>
  );
}

export default PaymentSuccess;
