import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import classes from "../../pages/Welcome.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function EnterOtp(props) {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [inputValue, setInputValue] = useState({
    otp: "",
  });

  const { otp } = inputValue;

  function handleOnChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }

  async function otpSubmitHandler(e) {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${BASE_URL}/user/verify-otp/${userId}`,
        {
          ...inputValue,
        },
        { withCredentials: true }
      );
      console.log(data);
      const { success, message } = data;
      if (success) {
        console.log("SUCCESSSSS");
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/welcome");
        }, 1500);
      } else {
        console.log("FAILURE");

        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }

    setInputValue({
      ...inputValue,
      otp: "",
    });
  }

  return (
    <>
      <ToastContainer />
      {/* Enter OTP FORM */}
      <form onSubmit={otpSubmitHandler}>
        <h4>Email Verification</h4>
        <p style={{ color: "grey" }}>
          Enter the OTP sent to your email <br /> Please check your inbox (and
          spam folder)
        </p>
        <input
          onChange={handleOnChange}
          value={otp}
          type="text"
          name="otp"
          placeholder="Enter OTP"
        />
        {/* <div className={classes.forgotPwd} style={{ textAlign: "right" }}>
          <p onClick={() => props.onForgot()}>Forgot Password?</p>
        </div> */}
        <div className={classes.formActions}>
          <div className={classes.logIn}>
            <button type="submit">
              Submit <ArrowForwardIcon fontSize="small" />
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default EnterOtp;
